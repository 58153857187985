import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getUserProfile, updateUserRecord } from "../../../store/actions/";
import PhoneInput, { isValidPhoneNumber} from 'react-phone-number-input';
import Cropper from "../Cropper/";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { useThemeColor } from '../../../utils/customHooks/useThemeColor'
import {ButtonContainer} from '../../Common/Button'
import moment from 'moment'

import "react-phone-number-input/style.css";
import "./MyInfo.scss";
import { DateComponent } from "../../DateComponent";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export const MyInfoComponent = () => {
  const classes = useStyles();
  const themeColor = useThemeColor();
  const { handleSubmit, errors, control, watch, register, getValues } = useForm({
    mode: "onBlur",
  });

  const [showWeddingAnniversary, setShowWeddingAnniversary] = useState(false);
  const [mobile, setMobile] = useState('');
  const userData = JSON.parse(localStorage.getItem("user"));
  const initialValues = {
    salutation: `${userData.responseData.userSalutation ?? ""}`,
    firstName: `${userData.responseData.userFirstName ?? ""}`,
    middleName: `${userData.responseData.userMiddleName ?? ""}`,
    lastName: `${userData.responseData.userLastName ?? ""}`,
    ageGroup: "",
    gender: "",
    mobile: "",
    birthDate: "",
    weddingDate: "",
    birthDate: "",
  };
  const [birthDateData, setData] = useState({
    birthDay: "",
    weddingDate: ""
  })

  const dispatch = useDispatch();
  const [mobileError, setMobileError] = useState("");


  const ipData = useSelector((state) => state.ipData);
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const orgInfoList = useSelector(
    (state) => state.orgInfoList.responseData,
    shallowEqual
  );
  const updateUserData = useSelector(
    (state) => state.updateUserData,
    shallowEqual
  );
  const userProfileData = useSelector(
    (state) => state.getUserProfileData.responseData,
    shallowEqual
  );

  const backgroundColor = themeColor.color;

  const [marital, setMaritalStatus] = useState('')

  const handleCallback = () => {
    dispatch(getUserProfile({'orgId': orgInfo.orgId, 'userId': userData?.responseData?.userId}))
  }

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      salutation,
      weddingDate,
      suffix,
      maritalStatus,
      gender,
      middleName,
      ageGroup} = data;

    // if(!mobile) return setMobileError('Mobile is Required');

    const payload = {
      firstName,
      lastName,
      salutation,
      weddingDate: birthDateData.weddingDate,
      suffix,
      maritalStatus,
      middleName,
      birthDate: birthDateData.birthDay,
      gender,
      ageGroup,
      userId: userData.responseData.userId,
      orgId: orgInfo.orgId,
      mobile
  }
    dispatch(updateUserRecord(payload, handleCallback))
  };


  useEffect(() => {
    if (userProfileData) setMaritalStatus(userProfileData?.userMaritalStatus)
  }, [userProfileData?.userMaritalStatus])

  const status = watch('maritalStatus')
  useEffect(() => {
    if (status) {
      setMaritalStatus(getValues('maritalStatus'))
    }
  }, [status])

  useEffect(() => {
    // console.log(userProfileData, 'userProfileData?.userPhoneNumber')
    if(userProfileData?.userPhoneNumber){
      setMobile(`+${userProfileData?.userPhoneNumber}`)
    }
    if (userProfileData?.userBirthDay) {
      setData({...birthDateData, birthDay: userProfileData?.userBirthDay, weddingDate: userProfileData?.userWeddingDate})
    }
  }, [userProfileData?.userPhoneNumber, userProfileData?.userBirthDay])

  const getDate = (date, type) => {
    let temp = { ...birthDateData };
    temp[type] = date;
    setData(temp);
  };

  return (
    <Paper className="w-100 page-wrapper my-info-page my-8 p-6">
      <div>
        <span className="font-size-h3 mr-2">Personal Information</span>
        <span className="text-muted">update your personal informaiton</span>
      </div>
      <hr />
      {/* <div className="font-weight-bolder font-size-h2">Member's Info</div> */}
      <div className="col-md-10 m-auto">
        <Cropper userId={userData.responseData.userId} orgId={orgInfo.orgId} />
        {userProfileData && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form fv-plugins-bootstrap fv-plugins-framework mt-10 text-muted"
          >
            <div className="col-md-6 m-auto">
              <div className="fv-plugins-icon-container mb-8 ">
                <div className="position-relative">
                  <Controller
                  as={<TextField
                    // onChange={formik.handleChange}
                    id="outlined-select-salutation"
                    label="Salutation"
                    defaultValue={userProfileData?.userSalutation || ""}
                    variant="outlined"
                    fullWidth
                    name="salutation"
                    select
                  >
                    {orgInfoList &&
                      orgInfoList.salutationsList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>}
                  control={control}
                  name="salutation"
                  defaultValue={userProfileData?.userSalutation || ""}
                  rules={{

                  }}
                  />
                </div>
                {errors.salutation ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {errors.salutation.message}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="fv-plugins-icon-container mb-8 ">
                <div className="position-relative">
                  <Controller
                  as={<TextField
                    // onChange={formik.handleChange}
                    id="outlined-select-salutation"
                    label="Suffix"
                    defaultValue={userProfileData?.userSuffix || ""}
                    variant="outlined"
                    fullWidth
                    name="suffix"
                    select
                  >
                    <MenuItem value="">Please select one</MenuItem>
                    {orgInfoList &&
                      orgInfoList.suffixList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>}
                  control={control}
                  name="suffix"
                  defaultValue={userProfileData?.userSuffix || ""}
                  />
                </div>
                {/* {formik.touched.suffix && formik.errors.suffix ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.suffix}</div>
                  </div>
                ) : null} */}
              </div>
              <div className="fv-plugins-icon-container mb-8 ">
                <div className="position-relative">
                  <Controller
                    as={
                      <TextField
                        label="First Name"
                        name="firstName"
                        variant="outlined"
                        type="text"
                        fullWidth
                        defaultValue={userProfileData.userFirstName || ""}
                        // error={errors.firstName}
                        inputProps={{maxLength: 64, minLength: 2}}
                      />
                    }
                    name="firstName"
                    control={control}
                    defaultValue={userProfileData.userFirstName || ""}
                    // rules={{
                    //   required: true,
                    // }}
                  />
                </div>
              </div>
              <div className="fv-plugins-icon-container mb-8 ">
                <div className="position-relative">
                    <Controller
                    as={
                      <TextField
                        label="Middle Name"
                        placeholder="Middle Name"
                        name="middleName"
                        variant="outlined"
                        type="text"
                        fullWidth
                        defaultValue={userProfileData.userMiddleName || ""}
                        // error={errors.firstName}
                        inputProps={{maxLength: 64, minLength: 2}}
                      />
                    }
                    name="middleName"
                    control={control}
                    defaultValue={userProfileData.userMiddleName || ""}
                    rules={{
                      // required: true,
                    }}
                  />
                </div>
              </div>
              <div className="fv-plugins-icon-container mb-8 ">
                <div className="position-relative">
                  <Controller
                    as={
                      <TextField
                        label="Last Name"
                        placeholder="Last Name"
                        name="lastName"
                        variant="outlined"
                        type="text"
                        fullWidth
                        defaultValue={userProfileData.userLastName || ""}
                        // error={errors.firstName}
                        inputProps={{maxLength: 64, minLength: 2}}
                      />
                    }
                    name="lastName"
                    control={control}
                    defaultValue={userProfileData.userLastName || ""}
                    rules={{
                      // required: true,
                    }}
                  />
                </div>
              </div>

              {/* <div className="fv-plugins-icon-container mb-8">
                <label className="font-weight-bolder">Mobile Phone</label>
                <PhoneInput
                  international
                  withCountryCallingCode
                  countryCallingCodeEditable={false}
                  defaultCountry={ipData ? ipData.country_code : null}
                  placeholder="Mobile"
                  value={mobile}
                  onChange={setMobile}
                  className={`form-control text-dark-50 h-auto py-5 px-6 mobile ${(mobile && !isValidPhoneNumber(mobile)) ? 'is-invalid' : (mobile && isValidPhoneNumber(mobile)) ? 'is-valid' :  ''}`}
                  name="mobile"
                  error={mobile ? (isValidPhoneNumber(mobile) ? undefined : 'Invalid mobile number') : 'Mobile number is required'}
                />
                {(mobile && !isValidPhoneNumber(mobile)) || mobileError ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{(mobile ? (isValidPhoneNumber(mobile) ? setMobileError('') : 'Invalid mobile number') : 'Mobile number is required') || mobileError}</div>
                  </div>
                ) : null}
              </div> */}
              <div className="fv-plugins-icon-container mb-8 ">
                <div className="position-relative">
                  <Controller
                  as ={<TextField
                    id="outlined-select-gender"
                    select
                    label="Gender"
                    name="gender"
                    fullWidth
                    variant="outlined"
                    defaultValue={userProfileData?.userGender|| ''}
                  >
                    <MenuItem value="">Please select One</MenuItem>
                    {orgInfoList &&
                      orgInfoList.genderList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>}
                  name="gender"
                  defaultValue={userProfileData?.userGender|| ''}
                  control={control}
                  rules={{

                  }}
                  />
                </div>
              </div>

              <div className="fv-plugins-icon-container mb-8 ">
                <div className="position-relative">
                  <Controller
                   as={
                   <TextField
                    id="outlined-select-age-group"
                    select
                    label="Age Group"
                    name="ageGroup"
                    fullWidth
                    variant="outlined"
                    defaultValue={userProfileData?.userAgeGroup || ''}
                  >
                    <MenuItem value="">Please select One</MenuItem>
                    {orgInfoList &&
                      orgInfoList.ageGroupList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>
                   }
                  defaultValue={userProfileData?.userAgeGroup || ''}
                  name="ageGroup"
                  control={control}
                  rules={{

                  }}
                  /> 
                </div>
              </div>

              <div className="fv-plugins-icon-container mb-8">
                <div className="position-relative">
                    {/* <Controller
                    as={
                      <TextField
                        label="Birthday"
                        name="birthDate"
                        type="date"
                        variant="outlined"
                        fullWidth
                        defaultValue={userProfileData.userBirthDay || ""}
                        InputLabelProps={{ shrink: true }}
                        // error={errors.firstName}
                      />
                    }
                    name="birthDate"
                    control={control}
                    defaultValue={userProfileData.userBirthDay || ""}
                    rules={{
                      // required: true,
                    }}
                  /> */}
                  {birthDateData?.birthDay && <DateComponent 
                    getDate={(date) => getDate(date, "birthDay")}
                    date={birthDateData.birthDay}
                  />}
                </div>
              </div>
              <div className="fv-plugins-icon-container mb-8 mt-3">
                <div className="position-relative">
                  <Controller
                  as={<TextField
                    id="outlined-select-marital-status"
                    select
                    label="Marital Status"
                    name="maritalStatus"
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    fullWidth
                    defaultValue={userProfileData?.userMaritalStatus}
                    variant="outlined"
                    // ref={register}
                  >
                    <MenuItem value="">Please select One</MenuItem>
                    {orgInfoList &&
                      orgInfoList.maritalStatusList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>}
                  name="maritalStatus"
                  control={control}
                  defaultValue={userProfileData?.userMaritalStatus}
                  />
                </div>
              </div>
              { marital === 'Married' && <div
                className="fv-plugins-icon-container mb-5 "
                
              >
                <div className="position-relative">
                  {/* <Controller
                  as={<TextField
                    id="outlined-with-birthdate"
                    label="Wedding Anniversary"
                    name="weddingDate"
                    type="date"
                    // format="dd/mm/yyyy"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    defaultValue={userProfileData?.userWeddingDate || ''}
                    variant="outlined"
                  />}
                  name="weddingDate"
                  control={control}
                  defaultValue={userProfileData?.userWeddingDate || ''}
                  /> */}
                {birthDateData?.weddingDate && <DateComponent 
                  getDate={(date) => getDate(date, "weddingDate")}
                  date={birthDateData.weddingDate}
                  title="Wedding"
                />}
                </div>
              </div>}
            </div>
            <div className="col-md-6 mx-auto d-flex justify-content-between ">
              <ButtonContainer
                type="submit"
                disabled={updateUserData.loading}
                // style={primaryBgColor}
                backgroundColor={backgroundColor}
                className="py-4 col-md-5"
              >
                <span>
                  {updateUserData.loading ? "Please wait..." : "update"}
                </span>
                {updateUserData.loading && (
                  <span className="spinner spinner-white ml-2"></span>
                )}
              </ButtonContainer>
              {/* <button className="btn btn-secondary ml-3 px-9">Cancel</button> */}
              <Link component={Button} to="/dashboard" elevation={0} className="px-5 py-4 col-sm-5" variant="contained">
                Cancel
              </Link>
            </div>
          </form>
        )}
      </div>
    </Paper>
  );
};


// const monthDay = orgInfoList?.daysAndMonthsDropDownList.reduce(
//   (obj, item) => {
//     obj[item.name] = item.days;
//     return obj;
//   },
//   {}
// );

// const handleChange = ({ target: { name, value } }) => {
//   let temp = { ...data };
//   temp[name] = value;
//   if (name === "month" && Number(temp?.days) > monthDay[value]?.length) {
//     temp.days = "";
//   }
//   setData(temp);
// };

// let { open, title, fullName, year, month, days, ...rest } = data;
// let payload = { ...rest };
// if (year === "") year = "0000";
// let date = [year, month, days].join("-");
// if (title === "Birthday") {
//   if (month && days) payload.birthDate = date;
//   dispatch(
//     adminUpdateUserProfileExpress(
//       {
//         ...payload,
//         orgId: orgInfo.orgId,
//         adminUserId: user?.responseData?.userId,
//       },
//       () => {
//         onClose();
//         getTableList(tabIndex);
//       }
//     )
//   );
// }